<template>
    <div class="content">
        <!-- 搜索栏 -->
        <div class="el-row">
            <el-button type="success" @click="addSalesmanSourceDialog">新增</el-button>
        </div>
        <!-- 推销员列表 -->
        <div class="el-row mg-t-20">
            <el-table
                    :data="tableData.list"
                    border
                    style="width: 100%">
                <el-table-column
                        prop="id"
                        label="id"
                        width="100">
                </el-table-column>
                <el-table-column
                        prop="sourceName"
                        label="来源">
                </el-table-column>
                <el-table-column
                        label="操作">
                    <template slot-scope="scope">
                        <div class="flx-row ali-c">
                            <span class="tip-text-info ml-15"
                                  @click="modifySalesmanSourceDialog(scope.row.id, scope.row.sourceName)">
                                修改
                            </span>
                            <span class="tip-text-delete ml-15" @click="deleteConfirm(scope.row.id)">
                                删除
                            </span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <!--翻页插件-->
        <div class="el-row mg-t-20">
            <el-pagination
                    align="center"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="tableData.pageNum"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="tableData.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tableData.total">
            </el-pagination>
        </div>
        <!--弹窗对话框-->
        <el-dialog
                :title="dialog.title"
                :visible.sync="dialog.visible"
                width="50%"
                :close-on-click-modal="false"
                :close-on-press-escape="false"
                :before-close="handleDialogClose">
            <!--添加推销员-->
            <div class="el-row" v-if="dialog.salesmanSource.visible">
                <el-form :model="dialog.salesmanSource" :rules="dialog.salesmanSource.rules" ref="dialog.salesmanSource">
                    <el-form-item label="来源名称" prop="sourceName">
                        <el-input class="inputBox" v-model="dialog.salesmanSource.sourceName"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
            <el-button @click="handleDialogClose">取 消</el-button>
            <el-button type="primary" @click="submitAddOrModifySalesmanSource">确 定</el-button>
        </span>
        </el-dialog>
    </div>
</template>

<script>
    import { salesmanSourceBackstage, addSalesmanSource, updateSalesmanSource, deleteSalesmanSource } from "@/api/maketing/internal_purchase";
    export default {
        name: "salesman_source_managed",
        data() {
            return {
                tableData: {
                    list: [],
                    pageSize: 10
                },
                dialog: {
                    title: '提示',
                    visible: false,
                    salesmanSource: {
                        id: null,
                        sourceName: null,
                        visible: false,
                        rules: {
                            sourceName: [
                                {required: true, message: "来源名称不能为空", trigger: "blur"}
                            ]
                        }
                    }
                }
            }
        },
        mounted() {
            this.initialTable();
        },
        methods: {
            //查询初始化
            initialTable() {
                let param = {
                    pageSize: this.tableData.pageSize,
                    pageNum: 1
                }
                salesmanSourceBackstage(param).then(res => {
                    this.tableData = res.data.body
                })
            },
            //每页显示行数
            handleSizeChange(val) {
                this.tableData.pageSize = val
                this.getSalesmanSourceByPage()
            },
            //翻页
            handleCurrentChange(val) {
                this.tableData.pageNum = val
                this.getSalesmanSourceByPage()
            },
            //条件查询
            getSalesmanSourceByPage() {
                let param = {
                    pageNum: this.tableData.pageNum,
                    pageSize: this.tableData.pageSize
                }
                salesmanSourceBackstage(param).then(res => {
                    this.tableData = res.data.body
                })
            },
            //关闭对话框前的操作
            handleDialogClose() {
                this.dialog.salesmanSource.visible = false
                this.dialog.visible = false
                this.dialog.salesmanSource.id = null
                this.dialog.salesmanSource.sourceName = null
            },
            //点击添加按钮
            addSalesmanSourceDialog() {
                this.dialog.title = '添加来源'
                this.dialog.visible = true
                this.dialog.salesmanSource.visible = true
            },
            //提交添加推销员之前的表单校验
            submitAddOrModifySalesmanSource() {
                this.$refs["dialog.salesmanSource"].validate(valid => {
                    if (!valid) {
                        this.$message({
                            message: "表单信息不完整（有误）",
                            type: "warning"
                        });
                        return;
                    }
                    //id为空是新增
                    if (this.dialog.salesmanSource.id == null) {
                        this.submitAddSalesmanSourceForm();
                    } else { // id不为空是修改
                        this.submitModifySalesmanSourceForm();
                    }

                });
            },
            //表单校验完后调用这方法提交请求
            submitAddSalesmanSourceForm() {
                let param = {
                    sourceName: this.dialog.salesmanSource.sourceName,
                }
                addSalesmanSource(param).then(res => {
                    this.handleDialogClose()
                    this.getSalesmanSourceByPage()
                    if(res.data.code === 200) {
                        this.$message.success('添加成功')
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            modifySalesmanSourceDialog(id, name) {
                this.dialog.title = '修改来源'
                this.dialog.visible = true
                this.dialog.salesmanSource.visible = true
                this.dialog.salesmanSource.id = id
                this.dialog.salesmanSource.sourceName = name
            },
            submitModifySalesmanSourceForm() {
                let param = {
                    id: this.dialog.salesmanSource.id,
                    sourceName: this.dialog.salesmanSource.sourceName,
                }
                updateSalesmanSource(param).then(res => {
                    this.handleDialogClose()
                    this.getSalesmanSourceByPage()
                    if(res.data.code === 200) {
                        this.$message.success('修改成功')
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
            },
            deleteConfirm(id) {
                this.$confirm('确定删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteSalesmanSource({id: id}).then(res =>{
                        this.getSalesmanByPage()
                        this.initialTable()
                        if(res.data.code === 200) {
                            this.$message({
                                type: 'success',
                                message: '删除成功!'
                            });
                        } else {
                            this.$message.error(res.data.msg)
                        }
                    })
                }).catch(() => {

                });
            },
        }
    }
</script>

<style scoped>
    .content {
        padding: 20px
    }

    .inputBox {
        width: 200px;
    }

    .mg-r-20 {
        margin-right: 20px;
    }

    .mg-t-20 {
        margin-top: 20px;
    }
</style>
